#tabbed-content {
	display: none;

	.is-active a {
		background-color: $red;
	}
}

template {
	display: block;
}

#app {
	.tabs__link {
		&.is-active {
			background-color: $primary;
			color: $white;
			border-color: $primary;
		}
	}

	.tabs-content__item {
		display: none;

		&.is-active {
			display: block;
		}
	}
}